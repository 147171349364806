import {
  Filename,
  GenInfo,
  ModelInfo,
  PowerPaintTask,
  Rect,
  ServerConfig,
} from "../lib/types"
import { Settings } from "../lib/states"
import { convertToBase64, srcToFile } from "../lib/utils"
import axios from "axios"

// export const API_ENDPOINT = import.meta.env.DEV
//   ? import.meta.env.VITE_BACKEND + "/api/v1"
//   : "/api/v1"

const lsUuid = localStorage.getItem('lsUuid') ? localStorage.getItem('lsUuid') : '';


//// for dev
// // export const API_ENDPOINT = 'http://localhost:8080/api/v1';
// // export const API_ENDPOINT = 'http://localhost:4002/api/v1';
// // export const API_ENDPOINT = 'http://localhost:4010/api/v1';
// export const API_ENDPOINT = 'http://localhost:4009/api/v1';
// export const IMAGEPHOTO_BACKENDURL = 'http://localhost:4001';
// export const SUBSCRIPTION_BACKEND_URL = IMAGEPHOTO_BACKENDURL;
// export const subscriptionBasicPriceId = "price_1MJyehIwXirsfm9gD9c7ctve";
// export const subscriptionMiniPriceId = "price_1MJyQGIwXirsfm9gGTTnTuD9";
// export const kuraImagePhotoUrl = "http://localhost:8503";
// export const drawDreamerLink = "https://kura-draw-dreamer.spaceeight.net";
// export const loginPageLink = "http://localhost:8501" + `/login?fromUrl=${encodeURIComponent(window.location.origin)}&lsUuid=${lsUuid}`;
// export const signupPageLink = "http://localhost:8501" + `/signup?fromUrl=${encodeURIComponent(window.location.origin)}`;


//// for test deploy
// export const API_ENDPOINT = 'https://dokmp19-ec.spaceeight.net/api/v1';
// export const IMAGEPHOTO_BACKENDURL = 'https://dokmp19-ac.spaceeight.net'
// export const SUBSCRIPTION_BACKEND_URL = IMAGEPHOTO_BACKENDURL;
// export const subscriptionBasicPriceId = "price_1MRZjTIwXirsfm9gbYrBwdO5";
// export const subscriptionMiniPriceId = "price_1MRZffIwXirsfm9gPjWau3HS";
// export const kuraImagePhotoUrl = "https://do-bucket-image-photo-app-test.web.app";
// export const drawDreamerLink = "https://kura-draw-dreamer.spaceeight.net";
// export const loginPageLink = "https://authsso.spaceeight.net" + `/login?fromUrl=${encodeURIComponent(window.location.origin)}&lsUuid=${lsUuid}`;
// export const signupPageLink = "https://authsso.spaceeight.net" + `/signup?fromUrl=${encodeURIComponent(window.location.origin)}`;


//// for deploy
export const API_ENDPOINT = 'https://dokmp19-e.spaceeight.net/api/v1';
export const IMAGEPHOTO_BACKENDURL = 'https://dokmp19-a.spaceeight.net'
export const SUBSCRIPTION_BACKEND_URL = IMAGEPHOTO_BACKENDURL;
export const subscriptionBasicPriceId = "price_1MRZjTIwXirsfm9gbYrBwdO5";
export const subscriptionMiniPriceId = "price_1MRZffIwXirsfm9gPjWau3HS";
export const kuraImagePhotoUrl = "https://kura-image-photo.spaceeight.net";
export const drawDreamerLink = "https://kura-draw-dreamer.spaceeight.net";
export const loginPageLink = "https://authsso.spaceeight.net" + `/login?fromUrl=${encodeURIComponent(window.location.origin)}&lsUuid=${lsUuid}`;
export const signupPageLink = "https://authsso.spaceeight.net" + `/signup?fromUrl=${encodeURIComponent(window.location.origin)}`;



const api = axios.create({
  baseURL: API_ENDPOINT,
})

export default async function inpaint(
  imageFile: File,
  settings: Settings,
  croperRect: Rect,
  extenderState: Rect,
  mask: File | Blob,
  paintByExampleImage: File | null = null,
  ipAdapterImage: File | null = null,
  ipAdapterScale: Number = 0.0,
  modelName: String | null = null,
) {
  // console.log(imageFile, settings, croperRect, extenderState, mask, paintByExampleImage);
    const imageBase64 = await convertToBase64(imageFile)
    const maskBase64 = await convertToBase64(mask)
    const exampleImageBase64 = paintByExampleImage
      ? await convertToBase64(paintByExampleImage)
      : null
    
    let ipa64Image;

    if (ipAdapterImage) {
      ipa64Image =  await convertToBase64(ipAdapterImage)
    }
  
    const res = await fetch(`${API_ENDPOINT}/inpaint`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        image: imageBase64,
        mask: maskBase64,
        ldm_steps: settings.ldmSteps,
        ldm_sampler: settings.ldmSampler,
        zits_wireframe: settings.zitsWireframe,
        cv2_flag: settings.cv2Flag,
        cv2_radius: settings.cv2Radius,
        hd_strategy: "Crop",
        hd_strategy_crop_triger_size: 640,
        hd_strategy_crop_margin: 128,
        hd_trategy_resize_imit: 2048,
        prompt: settings.prompt,
        negative_prompt: settings.negativePrompt,
        use_croper: settings.showCropper,
        croper_x: croperRect.x,
        croper_y: croperRect.y,
        croper_height: croperRect.height,
        croper_width: croperRect.width,
        use_extender: settings.showExtender,
        extender_x: extenderState.x,
        extender_y: extenderState.y,
        extender_height: extenderState.height,
        extender_width: extenderState.width,
        sd_mask_blur: settings.sdMaskBlur,
        sd_strength: settings.sdStrength,
        sd_steps: settings.sdSteps,
        sd_guidance_scale: settings.sdGuidanceScale,
        sd_sampler: settings.sdSampler,
        sd_seed: settings.seedFixed ? settings.seed : -1,
        sd_match_histograms: settings.sdMatchHistograms,
        sd_freeu: settings.enableFreeu,
        sd_freeu_config: settings.freeuConfig,
        sd_lcm_lora: settings.enableLCMLora,
        paint_by_example_example_image: exampleImageBase64,
        p2p_image_guidance_scale: settings.p2pImageGuidanceScale,
        enable_controlnet: settings.enableControlnet,
        controlnet_conditioning_scale: settings.controlnetConditioningScale,
        controlnet_method: settings.controlnetMethod
          ? settings.controlnetMethod
          : "",
        powerpaint_task: settings.showExtender
          ? PowerPaintTask.outpainting
          : settings.powerpaintTask,
        ip_adapter_image: ipa64Image,
        ip_adapter_scale: ipAdapterScale,
        model_name: modelName,
      }),
    })


    console.log('inpaint res', res);


    if (res.ok) {
      const blob = await res.blob()
      console.log('inpaint blob', blob);
      return {
        blob: URL.createObjectURL(blob),
        seed: res.headers.get("X-Seed"),
      }
    }
    const errors = await res.json()
    console.log(errors);
    throw new Error(`Something went wrong: ${errors.errors}`)
}

export async function getServerConfig(): Promise<ServerConfig> {
  const res = await api.get(`/server-config`)
  // console.log('get-server-config', res);
  return res.data
}

export async function switchModel(name: string): Promise<ModelInfo> {
  const res = await api.post(`/model`, { name })
  return res.data
}

export async function switchPluginModel(
  plugin_name: string,
  model_name: string
) {
  return api.post(`/switch_plugin_model`, { plugin_name, model_name })
}

export async function currentModel(): Promise<ModelInfo> {
  const res = await api.get("/model")
  // console.log('model get', res)
  return res.data
}

export async function runPlugin(
  genMask: boolean,
  name: string,
  imageFile: File,
  upscale?: number,
  clicks?: number[][]
) {
  const imageBase64 = await convertToBase64(imageFile)
  const p = genMask ? "run_plugin_gen_mask" : "run_plugin_gen_image"
  const res = await fetch(`${API_ENDPOINT}/${p}`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      name,
      image: imageBase64,
      upscale,
      clicks,
    }),
  })
  if (res.ok) {
    const blob = await res.blob()
    return { blob: URL.createObjectURL(blob) }
  }
  const errMsg = await res.json()
  throw new Error(errMsg)
}

export async function getMediaFile(tab: string, filename: string) {
  const res = await fetch(
    `${API_ENDPOINT}/media_file?tab=${tab}&filename=${encodeURIComponent(
      filename
    )}`,
    {
      method: "GET",
    }
  )
  if (res.ok) {
    const blob = await res.blob()
    const file = new File([blob], filename, {
      type: res.headers.get("Content-Type") ?? "image/png",
    })
    return file
  }
  const errMsg = await res.json()
  throw new Error(errMsg.errors)
}

export async function getMedias(tab: string): Promise<Filename[]> {
  const res = await api.get(`medias`, { params: { tab } })
  return res.data
}

export async function downloadToOutput(
  image: HTMLImageElement,
  filename: string,
  mimeType: string
) {
  const file = await srcToFile(image.src, filename, mimeType)
  const fd = new FormData()
  fd.append("file", file)

  try {
    const res = await fetch(`${API_ENDPOINT}/save_image`, {
      method: "POST",
      body: fd,
    })
    if (!res.ok) {
      const errMsg = await res.text()
      throw new Error(errMsg)
    }
  } catch (error) {
    throw new Error(`Something went wrong: ${error}`)
  }
}

export async function getGenInfo(file: File): Promise<GenInfo> {
  const fd = new FormData()
  fd.append("file", file)
  const res = await api.post(`/gen-info`, fd)
  console.log('get gen-info', res);
  return res.data
}

export async function getSamplers(): Promise<string[]> {
  const res = await api.post("/samplers")
  return res.data
}

export async function postAdjustMask(
  mask: File | Blob,
  operate: "expand" | "shrink" | "reverse",
  kernel_size: number
) {
  const maskBase64 = await convertToBase64(mask)
  const res = await fetch(`${API_ENDPOINT}/adjust_mask`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      mask: maskBase64,
      operate: operate,
      kernel_size: kernel_size,
    }),
  })
  if (res.ok) {
    const blob = await res.blob()
    return blob
  }
  const errMsg = await res.json()
  throw new Error(errMsg)
}
